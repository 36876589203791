import { ReactNode } from "react";
import { formatFilledRichTextElement } from "@shared/contentful/contentful.utils";
import styles from "@components/ContentfulComponents/ComponentTextAssetAndCtas/HeroBanner/HeroBanner.module.scss";
import { BLOCKS } from "@contentful/rich-text-types";
import { TRichTextEntryNode } from "@shared/contentful/contentful.types";

export const formatBannerText = ({
	Tag,
	children,
	textColor,
}: {
	Tag: keyof JSX.IntrinsicElements;
	children: ReactNode;
	textColor?: string;
}) => {
	const colorBanner = textColor === "white" ? styles.heroBannerTextWhite : "";
	return formatFilledRichTextElement({ children, Tag, className: colorBanner });
};

export const getAdditionalRenderNodeOptions = (textColor?: string) => {
	return {
		[BLOCKS.PARAGRAPH]: (_node: TRichTextEntryNode, children: ReactNode) =>
			formatBannerText({ children, Tag: "p", textColor }),
		[BLOCKS.HEADING_1]: (_node: TRichTextEntryNode, children: ReactNode) =>
			formatBannerText({ children, Tag: "h1", textColor }),
		[BLOCKS.HEADING_2]: (_node: TRichTextEntryNode, children: ReactNode) =>
			formatBannerText({ children, Tag: "h2", textColor }),
		[BLOCKS.HEADING_3]: (_node: TRichTextEntryNode, children: ReactNode) =>
			formatBannerText({ children, Tag: "h3", textColor }),
		[BLOCKS.HEADING_4]: (_node: TRichTextEntryNode, children: ReactNode) =>
			formatBannerText({ children, Tag: "h4", textColor }),
		[BLOCKS.HEADING_5]: (_node: TRichTextEntryNode, children: ReactNode) =>
			formatBannerText({ children, Tag: "h5", textColor }),
		[BLOCKS.HEADING_6]: (_node: TRichTextEntryNode, children: ReactNode) =>
			formatBannerText({ children, Tag: "h6", textColor }),
	};
};
