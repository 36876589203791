import { getPropsForImages } from "@components/CustomImage/custom-images.utils";
import CustomContentImage from "@components/CustomImage/CustomContentImage/CustomContentImage.component";
import CtasCollection from "@components/ContentfulComponents/ComponentTextAssetAndCtas/CtasCollection/CtasCollection.component";
import { EButtonSize } from "@components/Button/types";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";
import { getAdditionalRenderNodeOptions } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/HeroBanner/HeroBanner.utils";
import { THeroBanner } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/HeroBanner/HeroBanner.component";

import styles from "@components/ContentfulComponents/ComponentTextAssetAndCtas/HeroBanner/HeroBanner.module.scss";

export interface IHeroBannerContentProps extends THeroBanner {
	isMobile: boolean;
}

const HeroBannerContent = ({
	isMobile,
	asset: data,
	ctasCollection,
	openLinksInANewTab,
	richTextContent,
	textColor,
}: IHeroBannerContentProps) => {
	if (!data?.asset) return null;

	const mobileProps = getPropsForImages({
		image: data.asset,
	});

	const desktopProps = getPropsForImages({
		image: data.assetDesktop ?? data.asset,
	});

	return (
		<>
			<div className="relative">
				<CustomContentImage
					mobile={mobileProps}
					tablet={desktopProps}
					desktop={desktopProps}
					priority
				/>
				{isMobile && (
					<div className={styles.heroBannerCta} data-testid="HeroBanner_cta-mobile">
						<CtasCollection ctasCollection={ctasCollection} size={EButtonSize.Sm} />
					</div>
				)}
			</div>

			<div className={styles.heroBannerTextCta}>
				{renderRichTextWithLinks({
					richTextDocument: richTextContent?.json,
					additionalRenderNodeOptions: getAdditionalRenderNodeOptions(textColor),
					openLinksInANewTab,
				})}
				{!isMobile && (
					<div className={styles.heroBannerCta} data-testid="HeroBanner_cta-desktop">
						<CtasCollection ctasCollection={ctasCollection} size={EButtonSize.Sm} />
					</div>
				)}
			</div>
		</>
	);
};

export default HeroBannerContent;
