import { SwiperSlide } from "swiper/react";
import { useResponsiveOnLoad } from "@hooks/useDevice";
import HeroBannerCarouselLayout from "./HeroBannerCarouselLayout.component";
import HeroBannerContent from "./HeroBannerContent.component";
import { IHeroBannerCarouselCflData } from "@components/ContentfulComponents/BlockHeroBannerCarousel/type";

import styles from "@components/ContentfulComponents/ComponentTextAssetAndCtas/HeroBanner/HeroBanner.module.scss";

export interface IHeroBannerCarouselProps {
	data: IHeroBannerCarouselCflData;
	id: string;
}

const HeroBannerCarousel = ({ data, id }: IHeroBannerCarouselProps) => {
	const { devices, isLoad } = useResponsiveOnLoad();
	const prevArrow = `prev-${id}`;
	const nextArrow = `next-${id}`;
	const isMobile = devices.isMobile;
	const isMobileOrTablet = isMobile || devices.isTablet;

	if (!isLoad || !data?.contentCollection?.items?.length) return null;

	return (
		<div className="container-grid large-grid">
			<div className={styles.heroBannerWrapper}>
				<HeroBannerCarouselLayout
					prevArrow={prevArrow}
					nextArrow={nextArrow}
					isMobileOrTablet={isMobileOrTablet}
				>
					{data.contentCollection.items.map((banner) => (
						<SwiperSlide key={`swiper-slide-${banner.sys?.id}`}>
							<HeroBannerContent
								asset={banner.asset}
								ctasCollection={banner.ctasCollection}
								openLinksInANewTab={banner.openLinksInANewTab}
								richTextContent={banner.richTextContent}
								textColor={banner.textColor}
								isMobile={isMobile}
							/>
						</SwiperSlide>
					))}
				</HeroBannerCarouselLayout>
			</div>
		</div>
	);
};

export default HeroBannerCarousel;
