import React from "react";

import { EBLockCarouselIdentifiers } from "@components/ContentfulComponents/BlockCarousel/block-carousel.query";

import Icon, { EIcon } from "@components/Icon/Icon.component";
import { colors } from "@shared/styles/theme";

import styles from "./arrows.module.scss";

interface IProps {
	prevArrow: string;
	nextArrow: string;
	ariaLabels: Record<string, string>;
	classNames?: {
		buttons?: string;
		prev?: string;
		next?: string;
	};
}

const ArrowsOver = ({ prevArrow, nextArrow, ariaLabels, classNames }: IProps) => {
	const prevArrowAriaLabel = ariaLabels[EBLockCarouselIdentifiers.AriaLabelPrevious] ?? "previous";
	const nextArrowAriaLabel = ariaLabels[EBLockCarouselIdentifiers.AriaLabelNext] ?? "next";
	const buttonsStyle = classNames?.buttons ?? styles.navBtn;
	const prevStyle = classNames?.prev ?? styles.prevBtn;
	const nextStyle = classNames?.next ?? styles.nextBtn;

	return (
		<>
			<button
				className={`${prevArrow} ${buttonsStyle} ${prevStyle}`}
				aria-label={prevArrowAriaLabel}
				data-testid="ArrowsOver_prev-arrow"
			>
				<Icon icon={EIcon.ChevronLeft} color={colors.black} aria-label={prevArrowAriaLabel} />
			</button>
			<button
				className={`${nextArrow} ${buttonsStyle} ${nextStyle}`}
				aria-label={nextArrowAriaLabel}
				data-testid="ArrowsOver_next-arrow"
			>
				<Icon icon={EIcon.ChevronRight} color={colors.black} aria-label={nextArrowAriaLabel} />
			</button>
		</>
	);
};

export default ArrowsOver;
