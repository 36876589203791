import { gql } from "graphql-request";

import { IContentBlockCollection } from "@shared/contentful/contentful.types";
import { componentTranslationKeyFragment } from "../ComponentTranslationKey/fragment";
import { ITranslationKeyCflData } from "../ComponentTranslationKey/type";

export enum EBLockCarouselIdentifiers {
	BlockCarouselA11yNavigation = "block_carousel-a11y-navigation",
	AriaLabelNext = "aria-label_carousel-navigation-next",
	AriaLabelPrevious = "aria-label_carousel-navigation-previous",
}

export interface IBlockCarouselA11yDataVariables {
	identifier: EBLockCarouselIdentifiers;
}

export type TBlockCarouselA11yData = IContentBlockCollection<ITranslationKeyCflData>;

export const blockCarouselQuery = gql`
	${componentTranslationKeyFragment}
	query blockCarousel($identifier: String, $locale: String) {
		contentBlockCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				... on ContentBlock {
					contentCollection {
						items {
							...translationKey
						}
					}
				}
			}
		}
	}
`;
