import { ReactNode } from "react";
import { Swiper } from "swiper/react";
import SwiperCore, { Scrollbar, Navigation, Autoplay, Pagination } from "swiper";
import useGraphQL from "@hooks/useGraphql";
import ArrowsOver from "@components/Carousels/Arrows/ArrowsOver.component";
import {
	blockCarouselQuery,
	EBLockCarouselIdentifiers,
	IBlockCarouselA11yDataVariables,
	TBlockCarouselA11yData,
} from "@components/ContentfulComponents/BlockCarousel/block-carousel.query";

import styles from "./HeroBanner.module.scss";

import { getContentTranslations } from "@shared/contentful/translations.utils";

export interface IHeroBannerCarouselLayout {
	prevArrow: string;
	nextArrow: string;
	isMobileOrTablet: boolean;
	children: ReactNode;
}

SwiperCore.use([Navigation, Scrollbar, Autoplay, Pagination]);

const HeroBannerCarouselLayout = ({
	prevArrow,
	nextArrow,
	isMobileOrTablet,
	children,
}: IHeroBannerCarouselLayout) => {
	const { data: a11yData } = useGraphQL<TBlockCarouselA11yData, IBlockCarouselA11yDataVariables>(
		blockCarouselQuery,
		{
			variables: {
				identifier: EBLockCarouselIdentifiers.BlockCarouselA11yNavigation,
			},
		}
	);

	const a11yDataTranslation = getContentTranslations<string>(
		a11yData?.contentBlockCollection?.items?.[0].contentCollection?.items
	);

	const autoplay = {
		delay: 6000,
		pauseOnMouseEnter: !isMobileOrTablet,
		disableOnInteraction: false,
	};

	return (
		<>
			{!isMobileOrTablet && (
				<ArrowsOver
					prevArrow={prevArrow}
					nextArrow={nextArrow}
					ariaLabels={a11yDataTranslation}
					classNames={{ prev: styles.prevBtn, next: styles.nextBtn }}
				/>
			)}
			<Swiper
				navigation={{
					prevEl: `.${prevArrow}`,
					nextEl: `.${nextArrow}`,
				}}
				noSwipingSelector="button"
				allowTouchMove={isMobileOrTablet}
				spaceBetween={16}
				loop
				autoplay={autoplay}
				pagination={{
					dynamicBullets: true,
				}}
			>
				{children}
			</Swiper>
		</>
	);
};

export default HeroBannerCarouselLayout;
